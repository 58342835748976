// YH&H Project 

import React from 'react';

export function YHHproject () {
    return (
      <div className='bg-white text-center'>
        <h1>YHHproject Project</h1>
        <p>Details about the Sorcero project...</p>
        <p>More content goes here...</p>
      </div>
    );
  };
  
  export default YHHproject;
  