// adeptia

import React from 'react';

export function Adeptia () {
  return (
    <div className='bg-white text-center'>
      <h1>Adeptia Project</h1>
      <p>Details about the Adeptia project...</p>
      <p>More content goes here...</p>
    </div>
  );
};

export default Adeptia;
