// solbooks


import React from 'react';

export function SOLbooks () {
  return (
    <div className='bg-white text-center'>
      <h1>SOLBooks Project</h1>
      <p>Details about the Sorcero project...</p>
      <p>More content goes here...</p>
    </div>
  );
};

export default SOLbooks;
